<template>
  <div>
    <v-btn
      class="rounded-lg"
      color="primary"
      depressed
      outlined
      @click="dialog = true"
    >
      <v-icon left>mdi-file-arrow-up-down-outline</v-icon>
      Nhập xuất file</v-btn
    >

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>
          <span class="headline">Nhập xuất file</span>

          <v-spacer></v-spacer>

          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-btn
            class="mr-2"
            color="primary"
            depressed
            :loading="
              thirdStoreStatusRequest.value === 'loading-exportReportFile' ||
                thirdStoreStatusRequest.value === 'loading-importExcel'
            "
            outlined
            @click="$refs.uploader.click()"
            ><v-icon left>mdi-file-upload-outline</v-icon>Nhập file</v-btn
          >

          <input
            hidden
            accept=".xls,.xlsx"
            type="file"
            ref="uploader"
            @change="uploadExcelFile"
          />

          <v-btn
            color="primary"
            depressed
            :loading="
              thirdStoreStatusRequest.value === 'loading-exportReportFile' ||
                thirdStoreStatusRequest.value === 'loading-importExcel'
            "
            outlined
            @click="exportFile"
            ><v-icon left>mdi-file-download-outline</v-icon>Xuất file</v-btn
          >

          <div v-if="thirdStoreStatusRequest.value === 'error-importExcel'">
            <div class="mt-4 text-body-1 red--text font-weight-bold">
              Lỗi không thể nhập file:
            </div>

            <ul>
              <li
                v-for="error in thirdStoreStatusRequest.message"
                :key="error"
                class="text-body-1 red--text"
              >
                {{ error }}
              </li>
            </ul>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { exportExcelFile } from "@/core/composables";

export default {
  data() {
    return {
      dialog: false,
      file: null,
      errors: [],
      VUE_APP_API_URL: process.env.VUE_APP_API_URL
    };
  },

  computed: {
    thirdStoreStatusRequest() {
      return this.$store.getters["THIRD_STORE/statusRequest"];
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },

    async uploadExcelFile() {
      const excelFile = this.$refs.uploader.files;
      const formData = new FormData();
      const routeQuery = this.$route.query;

      Array.from(excelFile).forEach(f => {
        formData.append("file", f);
      });
      // Import request
      await this.$store.dispatch("THIRD_STORE/importExcel", {
        formData: formData,
        routeQuery: {
          filters: {
            supplier_id:
              typeof routeQuery.supplier_id === "string"
                ? [parseInt(routeQuery.supplier_id)]
                : typeof routeQuery.supplier_id === "object"
                ? routeQuery.supplier_id.map(item => parseInt(item))
                : null,
            stock_status: routeQuery.store_status
              ? parseInt(routeQuery.store_status)
              : null,
            fromPrice: parseInt(routeQuery.price_from),
            toPrice: parseInt(routeQuery.to)
          },
          search: routeQuery.search || null,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });

      // Check request status
      if (this.thirdStoreStatusRequest.value === "success-importExcel") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã nhập file thành công"
          }
        });
      }

      // Reset ref
      const input = this.$refs.uploader;
      input.type = "text";
      input.type = "file";
    },

    async exportFile() {
      await exportExcelFile(this, {
        storeName: "THIRD_STORE",
        fileName: "kho_ben_thu_ba"
      });
    }
  }
};
</script>

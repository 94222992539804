<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Kho bên thứ ba</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <div class="d-flex align-center justify-end">
        <button
          v-if="
            $route.query.supplier_id ||
              $route.query.store_status ||
              $route.query.site !== 'ALL'
          "
          class="blue--text btn-sm"
          @click="$emit('clearFilter')"
        >
          Xóa lọc
        </button>
      </div>
      <div class="">
        <div class="font-weight-bold mb-2">Nhà cung cấp</div>
        <v-autocomplete
          class="tp-filter-autocomplete"
          v-model="selectedSuppliers"
          :items="suppliers"
          dense
          deletable-chips
          hide-details
          hide-selected
          item-text="code"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          chips
          single-line
          small-chips
          multiple
          placeholder="Chọn nhà cung cấp"
        ></v-autocomplete>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">
          Hiển thị trên website
        </div>
        <v-radio-group v-model="selectedSite" class="mt-0" dense hide-details>
          <v-radio label="Tất cả" value="ALL"></v-radio>
          <v-radio
            v-for="(site, index) in ARRAY_LIST_MODEL_SITES"
            :key="index"
            :label="site.text"
            :value="site.id"
          ></v-radio>
          <v-radio label="Bị ẩn" value="NONE"></v-radio>
        </v-radio-group>
      </div>
      <div class="mt-5">
        <!-- Store Status -->
        <div class="font-weight-bold mb-2">Tình trạng kho</div>
        <v-radio-group
          v-model="selectedStoreStatus"
          class="mt-0"
          dense
          hide-details
        >
          <v-radio label="Tất cả" value="all"></v-radio>
          <v-radio label="Còn hàng" :value="1"></v-radio>
          <v-radio label="Hết hàng" :value="2"></v-radio>
          <v-radio label="Đang về" :value="3"></v-radio>
        </v-radio-group>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Giá nhập</div>
        <tp-input-range-price
          :value="priceFilter"
          @change="updatePriceFilter($event)"
        ></tp-input-range-price>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Khu vực</div>
        <v-autocomplete
          class="tp-filter-autocomplete"
          :items="areaList"
          dense
          deletable-chips
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          outlined
          chips
          single-line
          small-chips
          multiple
          placeholder="Chọn khu vực"
        ></v-autocomplete>
      </div>
    </v-card>
  </div>
</template>

<script>
import { ARRAY_LIST_MODEL_SITES, LIST_MODEL_SITES } from "@/core/constant";

export default {
  props: {
    priceFilter: {
      type: Object
    },
    siteFilter: {
      type: String
    },
    storeStatusFilter: {
      type: [Number, String]
    },
    suppliersFilter: {
      type: Array
    }
  },
  data() {
    return {
      areaList: [
        { id: "all", name: "Tất cả" },
        { id: "hanoi", name: "Hà Nội" },
        { id: "hcm", name: "Thành phố HCM" },
        { id: "global", name: "Nước ngoài" }
      ],
      ARRAY_LIST_MODEL_SITES,
      LIST_MODEL_SITES
    };
  },
  computed: {
    selectedStoreStatus: {
      get() {
        return this.storeStatusFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "storeStatusFilter",
          value: val
        });
      }
    },
    selectedSite: {
      get() {
        return this.siteFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "siteFilter",
          value: val
        });
      }
    },
    selectedSuppliers: {
      get() {
        return this.suppliersFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "suppliersFilter",
          value: val
        });
      }
    },

    suppliers() {
      return this.$store.getters["SUPPLIER/allSuppliers"];
    }
  },
  created() {
    this.$store.dispatch("SUPPLIER/getAllSuppliers", "?sup_cate_ids=1");
  },
  methods: {
    updatePriceFilter(val) {
      if (val.from && val.to && parseInt(val.to) >= parseInt(val.from)) {
        this.$emit("updateFilterProperty", {
          filterProperty: "priceFilter",
          value: val
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>

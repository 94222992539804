<template>
  <tp-modal max-width="768px" name="modal-3rd-store">
    <v-card slot-scope="props" flat>
      <v-card-title class="pb-4">
        <div class="font-weight-bold">
          {{
            thirdStore.id
              ? "Sửa sản phẩm kho bên thứ ba"
              : "Thêm sản phẩm kho bên thứ ba"
          }}
        </div>

        <v-spacer></v-spacer>

        <v-btn
          v-if="thirdStore.id"
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          :loading="
            thirdStoreStatusRequest.value === 'loading-updateThirdStore'
          "
          text
          @click="
            updateThirdStore(props.payload.optionId, props.payload.supplierId)
          "
        >
          Lưu
        </v-btn>

        <v-btn
          v-else
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          :loading="
            thirdStoreStatusRequest.value === 'loading-createThirdStore'
          "
          text
          @click="createThirdStore()"
        >
          Thêm
        </v-btn>

        <v-btn
          v-if="thirdStore.id"
          color="red accent-1"
          icon
          :loading="
            thirdStoreStatusRequest.value === 'loading-deleteThirdStores'
          "
          @click="deleteThirdStores()"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card class="px-5 pt-5" flat>
          <v-row class="mb-2">
            <v-col class="py-0" sm="6">
              <div class="font-weight-bold mb-2">Nhà cung cấp</div>

              <v-autocomplete
                v-model="thirdStore.supplier_id"
                :items="suppliers"
                class="tp-filter-autocomplete"
                deletable-chips
                dense
                hide-selected
                item-text="code"
                item-value="id"
                no-data-text="Không có dữ liệu"
                outlined
                placeholder="Chọn nhà cung cấp"
                single-line
              ></v-autocomplete>
            </v-col>

            <v-col class="py-0" md="6" sm="12">
              <v-checkbox
                v-model="thirdStore.is_online"
                label="Chỉ bán online"
              ></v-checkbox>
            </v-col>

            <v-col class="py-0" md="6" sm="12">
              <div class="font-weight-bold mb-2">Tùy chọn sản phẩm (SKU)</div>

              <div class="d_flex align-center px-0">
                <v-autocomplete
                  v-model="thirdStore.option_id"
                  :items="searchProductOptions"
                  :menu-props="{ maxWidth: '332px' }"
                  :search-input.sync="productSearchKey"
                  class="text-body-1 mr-3"
                  clearable
                  dense
                  flat
                  item-text="product_name"
                  item-value="id"
                  no-data-text="Không có dữ liệu"
                  no-filter
                  outlined
                  placeholder="Tìm SKU"
                  prepend-inner-icon="mdi-magnify"
                  solo
                >
                  <template v-slot:item="data">
                    <v-list-item-content :title="data.item.name">
                      <v-list-item-title
                        v-html="data.item.product_name"
                      ></v-list-item-title>
                      <div
                        class="grey--text text--darken-2 text-subtitle-2 item-subtitle"
                        v-html="data.item.name"
                      ></div>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>

                <v-btn
                  class="cyan lighten-5 btn-add px-0"
                  color="primary"
                  depressed
                  height="40px"
                  icon
                  width="40px"
                  @click="openModalProduct()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col class="py-0" md="6" sm="12">
              <div class="font-weight-bold mb-2">Trạng thái</div>

              <v-autocomplete
                v-model="thirdStore.stock_status"
                :items="stockStatusList"
                class="tp-filter-autocomplete"
                deletable-chips
                dense
                hide-selected
                item-text="name"
                item-value="id"
                no-data-text="Không có dữ liệu"
                outlined
                placeholder="Chọn trạng thái"
                single-line
              ></v-autocomplete>
            </v-col>

            <v-col class="py-0" md="6" sm="12">
              <div class="font-weight-bold mb-2">Thời gian chờ nhập hàng</div>

              <v-text-field
                v-model="thirdStore.waiting_time_for_import_goods"
                class="text-body-1"
                dense
                outlined
                placeholder="3 - 5 ngày"
                single-line
              ></v-text-field>
            </v-col>

            <v-col class="py-0" sm="12">
              <div class="font-weight-bold mb-2">Mô tả</div>

              <v-text-field
                v-model="thirdStore.description"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập mô tả"
                single-line
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import { showModalAlertError, showToastActionAlert } from "@/core/composables";
import { LIST_MODEL_TYPES } from "@/core/constant";

export default {
  data() {
    return {
      productSearchKey: null,
      stockStatusList: [
        { id: 1, name: "Còn hàng" },
        { id: 2, name: "Hết hàng" },
        { id: 3, name: "Đang về" }
      ],
      modelSiteConfigs: [],
      LIST_MODEL_TYPES
    };
  },
  computed: {
    searchProductOptions() {
      return this.$store.getters["PRODUCT_OPTION/searchProductOptions"];
    },
    suppliers() {
      return this.$store.getters["SUPPLIER/allSuppliers"];
    },
    thirdStore() {
      return this.$store.getters["THIRD_STORE/thirdStore"];
    },
    thirdStoreStatusRequest() {
      return this.$store.getters["THIRD_STORE/statusRequest"];
    }
  },
  watch: {
    productSearchKey(val) {
      if (val && val.length > 0) {
        this.$store.dispatch("PRODUCT_OPTION/searchProductOptions", {
          search: val
        });
      }
    },
    "thirdStore.id": {
      handler(value) {
        if (value) {
          this.getModalSiteConfigs();
        }
      },
      immediate: true
    }
  },
  created() {
    this.$store.dispatch("SUPPLIER/getAllSuppliers", "?sup_cate_ids=1");
  },
  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-3rd-store" });
      this.$store.dispatch("THIRD_STORE/resetThirdStore");
    },

    async getModalSiteConfigs() {
      this.modelSiteConfigs = await this.$store.dispatch(
        "MODEL_SITE_CONFIG/getModelSiteConfigsByModelId",
        {
          modelId: this.thirdStore.id,
          modelType: LIST_MODEL_TYPES.THIRD_INVENTORY
        }
      );
    },

    deleteThirdStores() {
      // Confirm
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa sản phẩm kho bên thứ ba <strong>${this.thirdStore.SKU} - ${this.thirdStore.supplier_code}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              const routeQuery = this.$route.query;

              await this.$store.dispatch("THIRD_STORE/deleteThirdStores", {
                data: {
                  ids: [
                    {
                      option_id: this.thirdStore.option_id,
                      supplier_id: this.thirdStore.supplier_id
                    }
                  ]
                },
                routeQuery: {
                  filters: {
                    supplier_id:
                      typeof routeQuery.supplier_id === "string"
                        ? [parseInt(routeQuery.supplier_id)]
                        : typeof routeQuery.supplier_id === "object"
                        ? routeQuery.supplier_id.map(item => parseInt(item))
                        : null,
                    stock_status: routeQuery.store_status
                      ? parseInt(routeQuery.store_status)
                      : null,
                    fromPrice: parseInt(routeQuery.price_from),
                    toPrice: parseInt(routeQuery.to)
                  },
                  search: routeQuery.search || null,
                  per_page: parseInt(routeQuery.limit),
                  cur_page: parseInt(routeQuery.page)
                }
              });
              if (
                this.thirdStoreStatusRequest.value ===
                "success-deleteThirdStores"
              ) {
                this.$modal.hide({ name: "modal-3rd-store" });
                this.$store.dispatch("THIRD_STORE/resetThirdStore");
                // Alert
                showToastActionAlert(this, { message: "Đã xóa thành công" });
              }
            }
          }
        }
      });
    },

    openModalProduct() {
      this.$modal.show({ name: "modal-product" });
    },

    async createThirdStore() {
      const routeQuery = this.$route.query;

      this.thirdStore.price = parseInt(this.thirdStore.price);
      // Create request
      await this.$store.dispatch("THIRD_STORE/createThirdStore", {
        data: { ...this.thirdStore },
        routeQuery: {
          filters: {
            supplier_id:
              typeof routeQuery.supplier_id === "string"
                ? [parseInt(routeQuery.supplier_id)]
                : typeof routeQuery.supplier_id === "object"
                ? routeQuery.supplier_id.map(item => parseInt(item))
                : null,
            stock_status: routeQuery.store_status
              ? parseInt(routeQuery.store_status)
              : null,
            fromPrice: parseInt(routeQuery.price_from),
            toPrice: parseInt(routeQuery.to)
          },
          search: routeQuery.search || null,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Alert
      if (this.thirdStoreStatusRequest.value === "success-createThirdStore") {
        await showToastActionAlert(this, { message: "Thêm mới thành công" });
        this.$modal.hide({ name: "modal-3rd-store" });
      }
      if (this.thirdStoreStatusRequest.value === "error-createThirdStore") {
        showModalAlertError(this, {
          title: "Lỗi thêm mới",
          message: this.thirdStoreStatusRequest.message
        });
      }
    },

    async updateThirdStore(optionId, supplierId) {
      const routeQuery = this.$route.query;
      const obj = { ...this.thirdStore };

      obj.price = parseInt(this.thirdStore.price);
      obj.old_option_id = optionId;
      obj.old_supplier_id = supplierId;
      // Create request
      await this.$store.dispatch("THIRD_STORE/updateThirdStore", {
        data: obj,
        routeQuery: {
          filters: {
            supplier_id:
              typeof routeQuery.supplier_id === "string"
                ? [parseInt(routeQuery.supplier_id)]
                : typeof routeQuery.supplier_id === "object"
                ? routeQuery.supplier_id.map(item => parseInt(item))
                : null,
            stock_status: routeQuery.store_status
              ? parseInt(routeQuery.store_status)
              : null,
            fromPrice: parseInt(routeQuery.price_from),
            toPrice: parseInt(routeQuery.to)
          },
          search: routeQuery.search || null,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Alert
      if (this.thirdStoreStatusRequest.value === "success-updateThirdStore") {
        showToastActionAlert(this, { message: "Đã lưu cập nhật" });
      }
      if (this.thirdStoreStatusRequest.value === "error-updateThirdStore") {
        showModalAlertError(this, {
          title: "Lỗi cập nhật",
          message: this.thirdStoreStatusRequest.message
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-add {
  border-radius: 4px !important;
  margin-bottom: 26px;
}
.item-subtitle {
  ::v-deep p {
    margin-bottom: 0 !important;
  }
}
</style>

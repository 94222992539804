<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :price-filter="priceFilter"
        :site-filter="siteFilter"
        :store-status-filter="storeStatusFilter"
        :suppliers-filter="suppliersFilter"
        @clearFilter="clearFilter"
        @updateFilterProperty="updateFilterProperty($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :selected-third-stores="selectedThirdStores"
        :selected-show-filter-id="itemPerPage"
        :search-key="searchKey"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
        @updateSelectedThirdStores="selectedThirdStores = $event"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items
          :selected-third-stores="selectedThirdStores"
          @updateSelectedThirdStores="selectedThirdStores = $event"
        ></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
    <!-- Start: Modal 3rd store -->
    <modal-3rd-store></modal-3rd-store>
    <!-- End: Modal 3rd store -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import Modal3rdStore from "./components/modal/Modal3rdStore";
import TopAction from "./components/TopAction";
import { updateFilterProperty } from "@/core/composables";

export default {
  components: {
    DataTableItems,
    FilterArea,
    Modal3rdStore,
    TopAction
  },
  data() {
    return {
      currentPage: 1,
      itemPerPage: 50,
      priceFilter: {
        from: null,
        to: null
      },
      selectedThirdStores: [],
      searchKey: null,
      siteFilter: "ALL",
      storeStatusFilter: null,
      suppliersFilter: null
    };
  },
  watch: {
    "$route.query"(val) {
      this.getThirdStoresFromRouteQuery(val);
    }
  },
  async created() {
    const route = this.$route;

    await this.getThirdStoresFromRouteQuery(route.query);
  },
  methods: {
    async clearFilter() {
      (this.currentPage = 1),
        (this.itemPerPage = 50),
        (this.priceFilter = {
          from: null,
          to: null
        }),
        (this.selectedThirdStores = []),
        (this.searchKey = null),
        (this.siteFilter = "ALL"),
        (this.storeStatusFilter = null),
        (this.suppliersFilter = null);
      await this.pushRouter();
    },
    async pushRouter() {
      await this.$router.push({
        name: "goods_3rd-store",
        query: {
          site: this.siteFilter,
          supplier_id:
            this.suppliersFilter && this.suppliersFilter.length > 0
              ? this.suppliersFilter
              : undefined,
          store_status:
            this.storeStatusFilter && this.storeStatusFilter !== "all"
              ? this.storeStatusFilter
              : undefined,
          price_from:
            this.priceFilter && this.priceFilter.from
              ? this.priceFilter.from
              : undefined,
          price_to:
            this.priceFilter && this.priceFilter.to
              ? this.priceFilter.to
              : undefined,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    async getThirdStoresFromRouteQuery(query) {
      if (query.page) {
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set price filter
        this.priceFilter =
          query.price_from && query.price_to
            ? { from: query.price_from, to: query.price_to }
            : { from: null, to: null };
        // Set filtered store status
        this.storeStatusFilter = query.store_status
          ? parseInt(query.store_status)
          : "all";
        // Set search key
        this.searchKey = query.search || null;
        // Set filtered site
        this.siteFilter = query.site || "ALL";
        // Set filtered suppliers
        this.suppliersFilter =
          typeof query.supplier_id === "string"
            ? [parseInt(query.supplier_id)]
            : typeof query.supplier_id === "object"
            ? query.supplier_id.map(item => parseInt(item))
            : [];

        // Get products
        await this.$store.dispatch("THIRD_STORE/getThirdStores", {
          filter: {
            site: this.siteFilter,
            supplier_id: this.suppliersFilter,
            stock_status:
              this.storeStatusFilter && this.storeStatusFilter !== "all"
                ? [this.storeStatusFilter]
                : null,
            fromPrice: this.priceFilter
              ? parseInt(this.priceFilter.from)
              : null,
            toPrice: this.priceFilter ? parseInt(this.priceFilter.to) : null
          },
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    async updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      await this.pushRouter();
    },

    updateFilterProperty(data) {
      updateFilterProperty(this, data.filterProperty, data.value);
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    async updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      await this.pushRouter();
    },

    async updateSearchKey(val) {
      this.searchKey = val.searchKey;
      this.currentPage = 1;
      // Push router query
      await this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <v-data-table
    v-model="selected"
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="thirdStoreStatusRequest.value === 'loading-getThirdStores'"
    loading-text="Đang tải dữ liệu"
    :items="thirdStores"
    item-key="id"
    show-select
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.SKU`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.SKU)"
          >
            {{ item.SKU }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.stock_status`]="{ item }">
      <v-chip
        class="font-weight-bold"
        :color="
          item.stock_status === 1
            ? 'green'
            : item.stock_status === 2
            ? 'red accent-2'
            : 'orange'
        "
        small
        outlined
      >
        {{
          item.stock_status === 1
            ? "Còn hàng"
            : item.stock_status === 2
            ? "Hết hàng"
            : "Đang về"
        }}
      </v-chip>
    </template>

    <template v-slot:[`item.waiting_time_for_import_goods`]="{ item }">
      {{ item.waiting_time_for_import_goods }}
    </template>

    <template v-slot:[`item.is_online`]="{ item }">
      <v-icon v-if="item.is_online" color="green"
        >mdi-check-circle-outline</v-icon
      >
    </template>

    <!-- <template v-slot:[`item.price`]="{ item }">
      {{ item.price | formatCurrency }}
    </template>

    <template v-slot:[`item.promotion`]="{ item }">
      {{ item.promotion ? item.promotion : "Không có" }}
    </template> -->
  </v-data-table>
</template>

<script>
export default {
  props: {
    selectedThirdStores: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: "SKU",
          align: "start",
          sortable: false,
          value: "SKU"
        },
        {
          text: "Nhà cung cấp",
          align: "start",
          sortable: false,
          value: "supplier_code"
        },
        {
          text: "Tình trạng kho",
          align: "start",
          sortable: false,
          value: "stock_status"
        },
        {
          text: "Thời gian chờ nhập hàng",
          align: "start",
          sortable: false,
          value: "waiting_time_for_import_goods"
        },
        {
          text: "Chỉ bán online",
          align: "center",
          sortable: false,
          value: "is_online"
        },
        {
          text: "Mô tả",
          align: "center",
          sortable: false,
          value: "description"
        }
      ]
    };
  },
  computed: {
    selected: {
      get() {
        return this.selectedThirdStores;
      },
      set(val) {
        this.$emit("updateSelectedThirdStores", val);
      }
    },
    thirdStores() {
      return this.$store.getters["THIRD_STORE/thirdStores"];
    },
    thirdStoreStatusRequest() {
      return this.$store.getters["THIRD_STORE/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      // Create our number formatter.
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    },

    async openModalProduct(product) {
      await this.$store.dispatch("PRODUCT/getProductById", product.id);
      await this.$store.dispatch(
        "PRODUCT_OPTION/getProductOptionsByProductId",
        product.id
      );

      this.$modal.show({
        name: "modal-product"
      });
    },

    async viewDetail(item) {
      await this.$store.dispatch("THIRD_STORE/setThirdStore", { ...item });
      this.$modal.show({
        name: "modal-3rd-store",
        payload: {
          optionId: item.option_id,
          supplierId: item.supplier_id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":_vm.thirdStoreStatusRequest.value === 'loading-getThirdStores',"loading-text":"Đang tải dữ liệu","items":_vm.thirdStores,"item-key":"id","show-select":""},on:{"click:row":function($event){return _vm.viewDetail($event)}},scopedSlots:_vm._u([{key:"item.SKU",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.SKU)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.SKU)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])]}},{key:"item.stock_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":item.stock_status === 1
          ? 'green'
          : item.stock_status === 2
          ? 'red accent-2'
          : 'orange',"small":"","outlined":""}},[_vm._v(" "+_vm._s(item.stock_status === 1 ? "Còn hàng" : item.stock_status === 2 ? "Hết hàng" : "Đang về")+" ")])]}},{key:"item.waiting_time_for_import_goods",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.waiting_time_for_import_goods)+" ")]}},{key:"item.is_online",fn:function(ref){
          var item = ref.item;
return [(item.is_online)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle-outline")]):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }